// const introBg = document.querySelector('.server__inner')
// const observerBgIntro = new IntersectionObserver(
// 	entries => {
// 		entries.forEach(entry => {
// 			entry.target.classList.toggle('show', entry.isIntersecting)
// 			update(4);
// 			// if (entry.isIntersecting) observer.unobserve(entry.target)
// 		})
// 	},
// 	{
// 		// threshold: 1
// 	}
// )
// observerBgIntro.observe(introBg)

//
// function elem_in_visible_area(selector) {
// 	let elem_p = $(selector),
// 		elem_p_height = elem_p.height(),
// 		offset_top_el = elem_p.offset().top,
// 		offset_bottom_el = offset_top_el + elem_p.height(),
// 		scrolled = $(window).scrollTop(),
// 		scrolled_bottom = scrolled + $(window).height();
// 	if (scrolled_bottom > offset_top_el && offset_bottom_el > scrolled) {
// 		return true;
// 	}
// 	return false;
// }
//
// const array = $(".progress div");
// let delay = 0;
//
// $(window).scroll(function () {
// 	if (elem_in_visible_area('.server__inner')) {
// 		const array = $(".progress div");
// 		cicle();
//
// 		function cicle(n) {
// 			for (let i = 0; i < array.length; i++) {
// 				checker(array[i], delay);
// 				delay += 800;
// 				if (array.length - i === 1) cicle();
// 			}
// 		}
//
// 		function checker(elem, delay) {
// 			setTimeout(function () {
// 				$(elem).addClass('active');
// 			}, delay);
// 		}
// 	}
// })
//


let items = document.querySelectorAll('.progress .circle');
let blocks = document.querySelectorAll('.server__list .server__item');

for (let i = 0; i < items.length; i++) {
	blocks[i].addEventListener('mouseenter', function () {
		items[i].style.backgroundColor = "#97C07C";
	});

	blocks[i].addEventListener('mouseleave', function () {
		items[i].style.backgroundColor = "#C0C0C0";
	});
}
