import Swiper from 'swiper';
import {Navigation, Pagination} from 'swiper/modules';

new Swiper('.top-slider__slider', {
	modules: [Navigation, Pagination],
	slidesPerView: "auto",
	spaceBetween: 30,
	speed: 2500,
	autoplay: {
		delay: 2500,
	},
	navigation: {
		nextEl: '.top-slider__button_next',
		prevEl: '.top-slider__button_prev',
	},
	pagination: {
		el:
			'.top-slider__pagination',
		clickable: true,
	},
});
