function elem_in_visible_area(selector) {
	let elem_p = $(selector),
		elem_p_height = elem_p.height(),
		offset_top_el = elem_p.offset().top,
		offset_bottom_el = offset_top_el + elem_p.height(),
		scrolled = $(window).scrollTop(),
		scrolled_bottom = scrolled + $(window).height();
	if (scrolled_bottom > offset_top_el && offset_bottom_el > scrolled) {
		return true;
	}
	return false;
}

const array = $(".why__progress div");
let delay = 0;

$(window).scroll(function () {
	if (document.querySelector('div').classList.contains('why__inner') && elem_in_visible_area('.why__inner')) {
		const array = $(".why__progress div");
		let delay = 0;
		cicle();

		function cicle(n) {
			for (let i = 0; i < array.length; i++) {
				checker(array[i], delay);
				delay += 800;
				if (array.length - i === 1) cicle();
			}
		}

		function checker(elem, delay) {
			setTimeout(function () {
				$(elem).addClass('active');
			}, delay);
		}
	}
})

let items2 = document.querySelectorAll('.why__progress .why__progress-item');
let blocks2 = document.querySelectorAll('.why__list .why__item');

for (let i = 0; i < items2.length; i++) {
	blocks2[i].addEventListener('mouseenter', function () {
		items2[i].style.backgroundColor = "#97C07C";
	});

	blocks2[i].addEventListener('mouseleave', function () {
		items2[i].style.backgroundColor = "#C0C0C0";
	});
}
