import '../pug/static/header/burger/burger'
import '../pug/components/top-slider/top-slider'
import '../pug/components/installation/installation'
import '../pug/components/faq/faq'
import '../pug/components/server/server'
import '../pug/components/about-us/why/why'
import '../pug/components/servers/servers-intro/servers-intro'
import '../pug/components/data/data-4/data-4'

import {_removeClasses, isMobile, ua} from "./files/functions";

function isIE() {
	var ua = window.navigator.userAgent;
	ua = navigator.userAgent;
	var is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
	return is_ie;
}

if (isIE()) {
	document.querySelector('html').classList.add('ie');
}
if (isMobile.any()) {
	document.querySelector('html').classList.add('_touch');
}

function testWebP(callback) {
	var webP = new Image();
	webP.onload = webP.onerror = function () {
		callback(webP.height === 2);
	};
	webP.src = "data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA";
}

testWebP(function (support) {
	if (support === true) {
		document.querySelector('html').classList.add('_webp');
	} else {
		document.querySelector('html').classList.add('_no-webp');
	}
});

function ibg() {
	if (isIE()) {
		let ibg = document.querySelectorAll("._ibg");
		for (var i = 0; i < ibg.length; i++) {
			if (ibg[i].querySelector('img') && ibg[i].querySelector('img').getAttribute('src') != null) {
				ibg[i].style.backgroundImage = 'url(' + ibg[i].querySelector('img').getAttribute('src') + ')';
			}
		}
	}
}

ibg();

window.addEventListener("load", function () {
	if (document.querySelector('.wrapper')) {
		setTimeout(function () {
			document.querySelector('.wrapper').classList.add('_loaded');
		}, 0);
	}
});

// images add alt

function setAlt() {
	var images = document.querySelectorAll('img');
	for (let i = 0; i < images.length; i++) {
		var srcPathImg = images[i].src
		var srcPathImgName = srcPathImg.substring(srcPathImg.lastIndexOf('/') + 1)
		// console.log(srcPathImgName.split('.').slice(0, -1).join('.'));
		var srcPathImgNameView = srcPathImgName.split('.').slice(0, -1).join('.');
		// console.log(srcPathImgName.replace(/\.[^/.]+$/, ""));
		// console.log(path.parse(srcPathImgName).name);
		// console.log(path.parse(srcPathImgName).ext);
		// console.log(path.parse(srcPathImgName).base);
		images[i].setAttribute("alt", srcPathImgNameView);
		images[i].setAttribute("title", srcPathImgNameView);
	}
}

setAlt()


document.querySelector('.header__theme').addEventListener('click', () => {
	if (localStorage.getItem('theme') === 'dark') {
		localStorage.removeItem('theme');
	} else {
		localStorage.setItem('theme', 'dark');
	}
	addClassHTML();
});

function addClassHTML() {
	try {
		if (localStorage.getItem('theme') === 'dark') {
			document.querySelector('html').classList.add('dark');
		} else {
			document.querySelector('html').classList.remove('dark');
		}
	} catch (err) {
	}
}

addClassHTML();


const headerNav = document.querySelector('header');
const headerNavHeight = headerNav.offsetHeight;
document.documentElement.style.setProperty('--scroll-padding', headerNavHeight - 1 + 'px')


const menuLinkSubmenu = document.querySelector('.menu__link_submenu');


document.addEventListener('click', docAction);

function docAction(e) {
	const targetElement = e.target;
	if (document.documentElement.clientWidth < 1240) {
		if (targetElement.classList.contains('menu__link_submenu')) {
			targetElement.closest('.menu__item').classList.toggle('active')
		}
		if (!targetElement.closest('.menu__item') && document.querySelectorAll('.menu__item.active').length > 0) {
			_removeClasses(document.querySelectorAll('.menu__item.active'), 'active');
		}
	}
}


import '../pug/components/dedicated/dedicated'
import '../pug/components/location/server-prices/server-prices'

if (document.querySelector('.wrapper').classList.contains('luck')) {
	const luckGrid = document.querySelector('.luck__grid');
	const lookCards = document.querySelector('.look-cards');

	lookCards.addEventListener('click', () => {
		luckGrid.classList.toggle('look');
	})
}

import '../pug/components/support/support'
import '../pug/components/product/product'


import customSelect from 'custom-select';

customSelect('select');
