import Swiper from 'swiper';
import {Navigation, Pagination} from 'swiper/modules';

new Swiper('.data-4__slider', {
	modules: [Navigation, Pagination],
	slidesPerView: 5,
	spaceBetween: 43,
	slidesPerColumn: 1,
	speed: 2500,
	autoplay: {
		delay: 2500,
	},
	navigation: {
		nextEl: '.data-4__button_next',
		prevEl: '.data-4__button_prev',
	},
	breakpoints: {
		320: {
			slidesPerView: 1,
			spaceBetweenSlides: 10
		},
		768: {
			slidesPerView: 2,
		},
		// when window width is <= 999px
		999: {
			slidesPerView: 2,
			spaceBetweenSlides: 40
		}
	}
});
