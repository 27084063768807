import Swiper from 'swiper';
import {Navigation, Pagination} from 'swiper/modules';

new Swiper('.installation__right', {
	modules: [Navigation, Pagination],
	slidesPerView: "auto",
	spaceBetween: 30,
	speed: 2500,
	autoplay: {
		delay: 2500,
	},
	navigation: {
		nextEl: '.installation__button_next',
		prevEl: '.installation__button_prev',
	}
});
